import React from 'react';
import styled from '@emotion/styled'
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner, Wrap } from 'styles/structure';
import PersonCard from 'components/_card/Person';
import PeopleGrid from 'components/_grid/People';

const FeaturePeopleContainer = styled.div`
  overflow: hidden;
  
  & + * {
    margin-top: 8rem;
  }
  
  &:last-child {
    margin-bottom: 8rem;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 10.4rem;
    }
    
    &:last-child {
      margin-bottom: 10.4rem;
    }  
  }
`;

const FeaturePeopleHeader = styled.header`
  display: block;
  position: relative;
  
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 0.2rem;
    background-color: ${colors.yellow500};
    transform: translateX(-50%) translateY(-50%);
  }
  
  & + * {
    margin-top: 4.8rem;
  }
  
  span {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: ${colors.grey900};
    font-size: 1.8rem;
    font-weight: 600;
    padding: 0 2.4rem;
    
    &::before {
      right: 100%;
    }
    
    &::after {
      left: 100%;
    }
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    &::before {
      width: 100%;
    }
    
    & + * {
      margin-top: 6.4rem;
    }
  }
`;

const FeaturePeople = ({ title, members, variant, dense }) => {
  return (
    <FeaturePeopleContainer className={`${variant ? `FeaturePeople--${variant}` : ''}`}>
      <Inner>
        {title && (
          <FeaturePeopleHeader>
            <span>{title}</span>
          </FeaturePeopleHeader>
        )}

        <Wrap>
          <PeopleGrid dense={dense}>
            {members.map((member, i) => {
              if (member.person && member.person.document) {
                return (
                  <li key={`person-${i}`}>
                    <PersonCard
                      person={member.person.document}
                      variant={variant}
                    />
                  </li>
                )
              } else {
                return null;
              }
            })}
          </PeopleGrid>
        </Wrap>
      </Inner>
    </FeaturePeopleContainer>
  );
};

export default FeaturePeople;
