import React from "react";
import { Link } from 'gatsby';
import Content from "components/_ui/Content";
import styled from '@emotion/styled'
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';

const CardContainer = styled.div`
  header {
    & + * {
      margin-top: 0.8rem;
    }
    
    h4 {
      transition: color 0.08s ease-in-out;
    }
    
    span {
      display: block;
      color: ${colors.grey800};
      font-size: 1.6rem;
      margin-top: 0.4rem;
    }
  }
  
  &.is-link {
    &:hover {
      h4 {
        color: ${colors.orange500};
      }
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    header {
      & + * {
        margin-top: 1.6rem;
      }
    }
  }
`;

const CardImage = styled.div`
  position: relative;
  width: 100%;
  max-width: 40rem;
  overflow: hidden;
  
  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  & + * {
    margin-top: 1.6rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 3.2rem;
    }
  }
`;

const PersonCard = ({ person, variant }) => {
  const { data } = person;

  if (person.uid) {
    return (
      <CardContainer
        as={Link}
        to={`/bio/${person.uid}`}
        className='is-link'
      >
        {(data.image && variant !== 'past') && (
          <CardImage>
            <img src={data.image.url} alt={data.image.alt}/>
          </CardImage>
        )}

        {data.name && (
          <header>
            <h4>{data.name}</h4>

            {data.job_title && <span>{data.job_title}</span>}
            {data.organization && <span>{data.organization}</span>}
          </header>
        )}

        {data.bio && <Content content={data.bio}/>}
      </CardContainer>
    )
  } else {
    return (
      <CardContainer>
        {(data.image && data.image.url && variant !== 'past') && (
          <CardImage>
            <img src={data.image.url} alt={data.image.alt}/>
          </CardImage>
        )}

        {data.name && (
          <header>
            <h4>{data.name}</h4>

            {data.job_title && <span>{data.job_title}</span>}
            {data.organization && <span>{data.organization}</span>}
          </header>
        )}

        {data.bio && <Content content={data.bio}/>}
      </CardContainer>
    )
  }
};

export default PersonCard;
