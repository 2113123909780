import React from 'react';
import { graphql } from 'gatsby';
import Seo from 'components/Seo';
import Layout from 'components/Layout';
import SubHero from 'components/SubHero';
import FeaturePeople from 'components/FeaturePeople';
import TrapdoorGrid from 'components/_grid/Trapdoor';
import TrapdoorCard from 'components/_card/Trapdoor';
import { withPreview } from 'gatsby-source-prismic';

const RenderBody = ({ team }) => (
  <>
    <SubHero
      breadcrumbs={[
        {
          text: 'About Us',
          destination: '/about-us',
        },
      ]}
      heading={team.title}
      content={team.description}
    />

    <FeaturePeople
      members={team.team_members}
      title='Leading Edge Team'
    />

    <FeaturePeople
      members={team.board_members}
      title='Board Members'
      dense
    />

    <FeaturePeople
      members={team.past_members}
      title='Past Members'
      dense
      variant='past'
    />

    <TrapdoorGrid>
      {team.trapdoors.slice(0,2).map((card, i) => (
        <li>
          <TrapdoorCard
            key={i}
            heading={card.heading}
            content={card.content}
            buttonText={card.button_text}
            destination={card.destination}
          />
        </li>
      ))}
    </TrapdoorGrid>
  </>
);

const TeamPage = ({ data }) => {
  //Required check for no data being returned
  const team = data.allPrismicTeam.edges[0].node.data;

  return (
    <Layout>
      <Seo
        title={team.social_title || 'Our Team'}
        description={team.social_description}
        image={team.social_image}
      />

      <RenderBody team={team} />
    </Layout>
  );
};

export const query = graphql`
  {
    allPrismicTeam {
      edges {
        node {
          data {
            title {
              raw
            }
            description {
              raw
            }
            team_members {
              person {
                document {
                  ... on PrismicPerson {
                    uid
                    data {
                      name
                      job_title
                      image {
                        url
                        alt
                      }
                    }
                  }    
                }
              }
            }
            board_members {
              person {
                document {
                  ... on PrismicPerson {
                    data {
                      name
                      job_title
                      organization
                      image {
                        url
                        alt
                      }
                    }
                  }  
                }
              }
            }
            past_members {
              person {
                document {
                  ... on PrismicPerson {
                    data {
                      name
                      job_title
                      organization
                      image {
                        url
                        alt
                      }
                    }
                  }    
                }
              }
            }
            trapdoors {
              heading {
                raw
              }
              content {
                raw
              }
              button_text
              destination
            }
            social_title
            social_description
            social_image {
              url
            }
          }
        }
      }
    }
  }
`;

export default withPreview(TeamPage);